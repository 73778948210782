import React from "react"
import Layout from "../../template/Layout"
import styled from "styled-components"
import Head from "../../head";
import { RESPONSIVE_STYLES, TABLET_STYLES, WRAPPER_STYLES, POLICY_STYLES } from "../../AppConst"
import { SpWrapper } from "../common/SpWrapper"
import {
  Props as BreadcrumbsData,
} from "../common/Breadcrumbs"
import { SectionTitle } from '../common/SectionTitle'

import imgIsmsIso27001 from "../../images/isms_iso27001.jpg"


/**
 * Style
 */
const Container = styled.div`
  ${WRAPPER_STYLES}
  ${TABLET_STYLES}
  box-sizing: border-box;
  max-width: 960px;
`
const Wrapper = styled.div`
  ${POLICY_STYLES}
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-bottom: 32px;
  }
`

const ContentWrapper = styled.div`
  width: min(90%, 960px);
  margin: auto;
  padding-top: 56px;
  display: flex;
  flex-flow: column;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    padding-top: 24px;
  }
`
const Content = styled.div`
  font-size: 1.6rem;
  letter-spacing: 1.6px;
  line-height: 32px;
  color: #222222;
  display: inline-flex;
  padding: 40px 0 40px 24px;
  border-bottom: 1px solid #CBCBCB;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 1.4rem;
    letter-spacing: 1.4px;
    line-height: 26px;
    padding: 18px 0 18px 16px;
  }
`
const Item = styled.span`
  font-weight: 600;
  width: 176px;
  text-align: left;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 80px;
  }
`
const Text = styled.span`
  font-weight: 400;
  text-align: left;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: calc(100% - 80px);
  }
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10%;
  margin: auto;
  width: 100%;
  max-width: 280px;
  padding-top: 56px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding-top: 24px;
  }
`

const Logo = styled.img`
  width: 100%;
  max-width: 280px;
`

const Number = styled.span`
  font-size: 1.25rem;
  font-family: 'Tahoma','Verdana',sans-serif;
  line-height: 1.8;
  letter-spacing: 1.8px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 1.4rem;
    letter-spacing: 1.4px;
  }
`

export const IsmsLogo: React.VFC = () => {
  return (
    <LogoWrapper>
      <Logo src={imgIsmsIso27001} alt="ISMS / IS 765811 / ISO 27001" />
      <Number>IS 765811 / ISO 27001</Number>
    </LogoWrapper>
  )
}

export const IsmsInfo: React.VFC = () => {
  return (
    <ContentWrapper>
      <Content>
        <Item>事業者名</Item>
        <Text>ネクストライブ株式会社</Text>
      </Content>
      <Content>
        <Item>適合認証規格</Item>
        <Text>ISO/IEC27001:2013 / JIS Q 27001:2014</Text>
      </Content>
      <Content>
        <Item>認証登録番号</Item>
        <Text>IS 765811</Text>
      </Content>
      <Content>
        <Item>初回登録日</Item>
        <Text>2022年6月18日</Text>
      </Content>
      <Content>
        <Item>審査登録機関</Item>
        <Text>BSIグループジャパン株式会社</Text>
      </Content>
      <Content>
        <Item>認証登録範囲</Item>
        <Text>
          SaaS型クラウドサービスの開発・運営<br />
          ソフトウェアの開発・運用・保守<br />
          Webサイトの制作・運用・保守<br />
          IT人材育成サービスの開発・運営<br />
          システムエンジニアリングサービスの提供<br />
        </Text>
      </Content>
    </ContentWrapper>
  )
}

/**
 * Component
 */

type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
}

export const Security: React.FC<Props> = ({
  breadcrumbsData,
}) => {
  return (
    <Layout sidebar={false} breadcrumbsData={breadcrumbsData}>
      <Head
        title="情報セキュリティ方針 | ネクストライブ株式会社"
        description="ネクストライブ株式会社の情報セキュリティ方針ページです。
                    当社の運営における情報セキュリティ方針について掲載しています。"
      />
      <Container>
        <SpWrapper>
          <Wrapper>
            <SectionTitle title='security policy' subTitle='情報セキュリティ方針' />
            <section>
              <h3>基本理念</h3>
              <p>
                ネクストライブ株式会社（以下、当社）は、「0→1 INNOVATION（ゼロイチイノベーション）」を理念とし、事業を行っています。<br />
                当社の事業の中で取り扱うお客様の情報をはじめとする情報資産は、当社の経営基盤として極めて重要なものです。<br />
                漏洩、き損、滅失等のリスクから、情報資産を保護することの重要性を認識した役員や従業員を含めた情報資産を扱う者が本方針を遵守し、情報資産の機密性、完全性、可用性といった情報セキュリティを維持するための活動を実践します。
              </p>
            </section>
            <section>
              <h3>基本方針</h3>
              <ol>
                <li>
                  情報資産を保護するために、情報セキュリティ方針ならびにそれにまつわる規程類を策定し、これに従って業務を行うとともに、情報セキュリティに関連する法令、規制その他の規範、及びお客様との契約事項を遵守いたします。
                </li>
                <li>
                  情報資産に対して存在する漏洩、き損、滅失等のリスクを分析、評価するための基準を明確にし、体系的なリスクアセスメント方法を確立するとともに、定期的にリスクアセスメントを実施いたします。また、その結果に基づき必要かつ適切なセキュリティ対策を実施いたします。
                </li>
                <li>
                  担当役員を中心とした情報セキュリティ体制を確立するとともに、情報セキュリティに関する権限および責任を明確にいたします。また、すべての従業者が情報セキュリティの重要性を認識し、情報資産の適切な取り扱いを確実にするために、定期的に教育、訓練および啓発を行います。
                </li>
                <li>
                  情報セキュリティポリシーの遵守状況及び情報資産の取扱いについて、定期的に点検及び監査を行い、発見された不備や改善項目については速やかに是正処置を講じます。
                </li>
                <li>
                  情報セキュリティ上のイベントやインシデントの発生に対する適切な処置を講じるとともに、万一それらが発生した場合に際して、あらかじめ被害を最小限に留めるための対応手順を確立し、有事の際は速やかに対応するとともに、適切な是正処置を講じます。また、特に業務中断に関わるようなインシデントについては、その管理の枠組みを確立し、定期的に見直しを行うことにより当社の事業継続を確実にいたします。
                </li>
                <li>
                  基本理念を実現するための目標を定めた情報セキュリティマネジメントシステムを確立し、これを実行するとともに継続的に見直し、改善を行います。
                </li>
              </ol>
            </section>
            <section>
              <p>
                2022年3月1日 制定<br />
                ネクストライブ株式会社<br />
                代表取締役　狗巻 雄介
              </p>
            </section>
            <SectionTitle title='ISMS/ISO27001' subTitle='情報セキュリティマネジメントシステム' />
            <section>
              <p>当社は、情報セキュリティマネジメントシステム(ISMS)を構築し、国際標準規格であるISO27001の認証を取得しております。当社のサービスを安心してご利用いただけるよう、今後も情報セキュリティマネジメントの維持・改善を行い、情報セキュリティレベルの向上に努めて参ります。</p>
              <IsmsLogo />
              <IsmsInfo />
            </section>
          </Wrapper>
        </SpWrapper>
      </Container>
    </Layout>
  )
}
