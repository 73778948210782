import React from "react"
import { Security } from '../components/security_policy'

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `SECURITY POLICY`, path: `security_policy` },
]

export default function SecurityPolicyPage() {
  return (
    <Security breadcrumbsData={breadcrumbsData}/>
  )
}
